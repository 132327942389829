import { computed, inject, Injectable } from "@angular/core";
import { LegacyStateStoreService } from "@cq/app/core/page/services/legacy-state-store.service";
import { Contextualizer } from "@cq/app/diagnostic/context";

@Injectable()
export class PageContextService implements Contextualizer {
  private state = inject(LegacyStateStoreService);

  context = computed(() => {
    const state = this.state.current();
    if (state) {
      return {
        state,
      };
    } else {
      return undefined;
    }
  });
}
