import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import {
  Params,
  Router,
  RouterStateSnapshot,
  UrlSerializer,
} from "@angular/router";
import { EXACT, PARTIAL } from "@cq/app/core/routing/match-option";
import { RoutingService } from "@cq/app/core/routing/services/routing.service";
import { map, takeUntil } from "rxjs";
import { AccountStoreService } from "../stores/account-store.service";

const ACCOUNT_ROOT = "/upgrade/account";

interface AccountInformationNavigationOptions {
  allowDeactivation?: boolean;
  openAddAddress?: boolean;
  openAddNamedInsured?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class AccountNavigationService implements OnDestroy {
  private destroy$ = new EventEmitter<void>();
  private accountNumber?: string;

  isAccountActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isAccountActive()),
  );
  isAccountAddProductsActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isAccountInformationActive()),
  );
  isAccountInformationActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isAccountInformationActive()),
  );
  isAccountQuestionsActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isAccountQuestionsActive()),
  );
  isAccountQuestionsAvailable$ = this.account.isCreated$;

  constructor(
    private readonly account: AccountStoreService,
    private readonly router: Router,
    private readonly routing: RoutingService,
    private readonly urlSerializer: UrlSerializer,
  ) {
    account.account$.pipe(takeUntil(this.destroy$)).subscribe((account) => {
      this.accountNumber = account.accountNumber;
    });
  }

  isAccountActive() {
    return this.router.isActive(ACCOUNT_ROOT, PARTIAL);
  }

  isAccountAddProductsActive() {
    return this.isRouteActive(this.buildAccountAddProductsRoute());
  }

  isAccountInformationActive() {
    return this.isRouteActive(this.buildAccountInformationRoute());
  }

  isAccountQuestionsActive() {
    return this.isRouteActive(this.buildAccountQuestionsRoute());
  }

  isAccountQuestions(snapshot: RouterStateSnapshot) {
    /*
      I have to imagine there is a better way of identifying the destination of
      of a given route snapshot, but all of my attempts have come up empty.

      From memory, its fairly rare that we have any pressing need for this sort
      of behavior across the application, so this will do in a pinch.
    */
    const tree = this.router.createUrlTree(this.buildAccountQuestionsRoute());
    const accountQuestionsUrl = this.urlSerializer.serialize(tree);
    return snapshot.url === accountQuestionsUrl;
  }

  navigateToAccountAddProducts(accountNumber?: string) {
    const account = accountNumber ?? this.accountNumber;
    return this.router.navigate([ACCOUNT_ROOT, account, "add-products"]);
  }

  navigateToAccountInformation(
    accountNumber?: string,
    queryParams: Params = {},
    options: AccountInformationNavigationOptions = { allowDeactivation: false },
  ) {
    return this.router.navigate(
      this.buildAccountInformationRoute(accountNumber),
      {
        queryParams,
        info: {
          allowDeactivation: options.allowDeactivation,
          openAddAddress: options.openAddAddress,
          openAddNamedInsured: options.openAddNamedInsured,
        },
      },
    );
  }

  navigateToAccountQuestions(accountNumber?: string) {
    const account = accountNumber ?? this.accountNumber;
    return this.router.navigate([ACCOUNT_ROOT, account, "questions"]);
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
  }

  buildAccountAddProductsRoute(accountNumber?: string) {
    const routedAccountNumber = accountNumber ?? this.accountNumber;
    return [ACCOUNT_ROOT, routedAccountNumber, "add-products"];
  }

  buildAccountInformationRoute(accountNumber?: string) {
    const routedAccountNumber = accountNumber ?? this.accountNumber;
    if (routedAccountNumber) {
      return [ACCOUNT_ROOT, routedAccountNumber, "information"];
    } else {
      return [ACCOUNT_ROOT, "information"];
    }
  }

  private buildAccountQuestionsRoute() {
    return [ACCOUNT_ROOT, this.accountNumber, "questions"];
  }

  private isRouteActive(route: unknown[]) {
    const urlTree = this.router.createUrlTree(route);
    return this.router.isActive(urlTree, EXACT);
  }
}
